import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";



const About = lazy(() => import("./About"));
// const Leetcode = lazy(() => import("./Leetcode"));
const Gears = lazy(() => import("./Gears"));
// const Principles = lazy(() => import("./Principles"));
const Shelf = lazy(() => import("./Shelf"));
const Projects = lazy(() => import("./Projects"));

function Main() {
    return (
        <Suspense>
            <div>
                <Routes>
                    <Route path="/" element={<About />} />
                    {/* <Route path="/leetcode" element={<Leetcode />} /> */}
                    <Route path="/gears" element={<Gears />} />
                    {/* <Route path="/principles" element={<Principles />} /> */}
                    <Route path="/shelf" element={<Shelf />} />
                    <Route path="/projects" element={<Projects />} />
                </Routes>
            </div>
        </Suspense>
    );
}

export default Main;
